import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import "./components/Form/form.scss";
import MainRouter from "./routes/MainRouter";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import ErrorAlert from "./components/Alerts/Error";
import MessageAlert from "./components/Alerts/Message";
import Loader from "./components/Reusable/Loader";
import Popup from "./pages/AffiliateMarket/AffiliateOverview/Popup";
import { getChallengePricing } from "./store/reducers/payment";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { hasSeenModalFun } from "./store/reducers/dashboard";
import axios from "axios";

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#2b2b2b",
      light: "#fff",
    },
    secondary: {
      main: "#9292AB",
      light: "#FFED53",
    },
    background: {
      default: "#111142",
      paper: "rgba(255, 255, 255, 0.02)",
    },
    text: {
      hint: "rgba(143, 143, 143, 1)",
      secondary: "#8B8E93",
    },
    typography: {
      fontFamily: "Urbanist",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
            @font-face {
              font-family: 'Urbanist';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `,
      },
    },
    shape: {
      borderRadius: 12,
    },
  },
});

ReactGA.initialize("GTM-NSLVTNG");
function App() {
  const is_loading = useSelector((state) => state.loader.is_loading);
  const lng = useSelector((state) => state.settings.account_settings.language);
  const { i18n } = useTranslation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lng);
  }, [lng]);
  console.log = function () {};
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.auth.idToken);

  const email = useSelector((state) => state.auth.email);

  const { user_accounts } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getChallengePricing());
  }, [idToken, dispatch]);

  // const { hasSeenModal } = useSelector((state) => state.dashboard);

  // useEffect(() => {
  //   if (idToken && hasSeenModal && user_accounts.length > 0) {
  //     setOpen(true);
  //     dispatch(hasSeenModalFun());
  //   }
  // }, [hasSeenModal, idToken, dispatch, user_accounts]);

  const handleClose = () => {
    setOpen(false);
  };

  const checkEmailStatus = async (email) => {
    try {
      const response = await axios.get(
        `https://diligio.co.uk:5001/lookup/${email}`,
        {}
      );

      const { success } = response?.data;

      if (success === false) {
        setOpen(true);
      } else {
        setOpen(false);
      }

      console.log("response", response);
    } catch (error) {
      console.error("Error checking email status:", error);
    }
  };

  useEffect(() => {
    if (email) {
      checkEmailStatus(email);
    }
  }, [email]);

  return (
    <div className="App">
      {is_loading ? <Loader /> : false}
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <ErrorAlert />
        <MessageAlert />
        <MainRouter />
        <Popup />

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth
          PaperProps={{
            style: { width: "80vw", height: "80vh" },
          }}
        >
          <DialogContent
            style={{
              position: "relative",
              paddingTop: "40px",
              overflow: "hidden",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
            <iframe
              src="https://tokens.macandgray.com/"
              width="100%"
              height="100%"
              style={{ border: "none" }}
              title="Token iframe"
            ></iframe>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}

export default App;
